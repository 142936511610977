main {
  min-height: 100vh;
}

body {
  /* background-color: #3498db; */
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}

.sizes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.size {
  height: 40px;
  width: 40px;
  border: 1px solid black;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.activeSize {
  height: 40px;
  width: 40px;
  border: 1px solid red;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: orange;
  font-weight: bold;
}
.cartSize {
  height: 40px;
  width: 40px;
  border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: orange;
  font-weight: bold;
  margin-right: 10px;
}

.size:hover {
  transform: scale(1.2);
  box-shadow: 0px 0px 10px rgba(173, 216, 230, 0.8);
}

.cartBadge {
  position: relative;
  padding: 2px 6px;
  margin-right: 5px;
  background-color: #e74c3c;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  transition: transform 0.3s ease-in-out;
  font-weight: bold;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.searchBtn {
  padding: 15px;
  border: none;
  background: #ebeef1;
  color: #00a037;
  font-weight: 500;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50px;
  width: 250px;
  box-shadow: 10px 10px 10px -1px rgba(10, 99, 169, 0.16),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.7);
  color: black;
  box-shadow: inset 10px 10px 10px -1px rgba(10, 99, 169, 0.16),
    inset -10px -10px 10px -1px rgba(255, 255, 255, 0.7);
}

.custom-input:focus {
  outline: none; /* Remove the outline on focus */
}

.custom-bg {
  background-color: rgba(123, 118, 118, 0.2);
  transition: background-color 0.3s ease;
  padding: 20px;
}

.boxShadow {
}

.searchIcon {
  margin-right: 10px;
}

.carousalBg {
  background-color: palevioletred;
  border-radius: 70px;
}

.text-black {
  color: black;
}

.img-fluid {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  height: 500px;
  width: 500px;
}
.img-fluid:hover {
  transform: scale(1.05) translate(-5%, -5%);
  box-shadow: 0px 0px 5px rgba(173, 216, 230, 0.5);
}

.iconFb {
  padding: 10px;
  color: blue;
}
.iconTwitter {
  padding: 10px;
  color: skyblue;
}
.iconPin {
  padding: 10px;
  color: red;
}
.iconInsta {
  padding: 10px;
  color: rgb(205, 121, 135);
}

.productHeader {
  display: flex;
  align-items: center;
  width: 80vw;
}

.categories {
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  padding: 0px 10px;
  border: 1px solid black;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  white-space: nowrap;
}

.activeCat {
  margin-left: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  padding: 0px 10px;
  color: orange;
  border: 1px solid red;
}

.categories:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 10px rgba(173, 216, 230, 0.8);
}

.price-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 20px;
  font-weight: bold;
}

.price-filters label {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}

.price-filters input[type="radio"] {
  display: none;
}

.price-filters label {
}

.latestHeading {
  font-size: 20px;
  font-weight: 900;
}

.price-filter-label {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.activeFilter {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: #3498db;
  color: white;
}

.shopBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.seasonContainer {
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 30px;
}

.seasonHeading {
  font-weight: bolder;
  font-size: 36px;
}

.season button {
  padding: 10px;
  background-color: red;
  color: white;
  border: none;
  width: 200px;
  margin-bottom: 20px;
}

.homeProductImg {
  height: 321px;
  object-fit: cover;
}

.shopNow {
  display: none;
}

@media only screen and (max-width: 997px) {
  .custom-input {
    display: none;
  }
  .searchBtn {
    display: none;
  }
  .shopNow {
    display: block;
  }
  .seasonContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    gap: 30px;
  }
  .productHeader .categories{
    display: none;
  }
  .productHeader .latestHeading{
    display: none;
  }
}

.multiImgContainer {
  display: flex;
  gap: 20px;
  padding-top: 20px;
  justify-content: center;
}

.multiImg {
  height: 70px;
  width: 70px;
}

.cartImg {
  height: 40px;
  width: 40px;
}

.navRight {
  display: flex;
  padding-left: 20px;
  margin: 0;
}
